.filterContainer{
   text-align: center;
   color: rgb(255, 255, 255);
   font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.divPrincipal{
    display: grid;
}

select{
    background-color: rgb(158, 3, 176);
    color: aliceblue;
    box-shadow: 0 0 20px #4f9;
}