

.App{
  
   background-image:url('components/imagenes/879998.jpg');
   background-attachment: fixed;
   background-position: 100%;
   min-height: 900px;
   width: 100%;
  
 
   

  
   
   
}
/* 
 .cards{
   background-image: url('components/imagenes/fondo-cards.png');
   background-size: 100%;
   background-position: 100%;
   max-height: 700px;
   max-width: 1700px;
   margin-left: -2em;
   margin-right: -1em;
  
}

.linea1{
   color: grey;
   max-width: 1850px;
   margin-top: -8.1em;
  
}
  */
