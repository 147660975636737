
 

.contenedor{
    height: 480px;
    max-height: 550px;
    width: 300px;
    border-color: white;
    border-style: solid;
    border-width: 3px;
    background-color:#811b8a;
    border-radius: 30px;
    box-shadow: 0 0 20px #4f9;
    opacity: .85;
    color: white;
    margin-bottom: 2em;
    margin-left: 0.5em;
    display: grid;
    grid-template-rows:auto;
   
    
   
    
    
  
}

.contenedor:hover{
    opacity:1;
    zoom:normal;
    
}


.imagen{
   
    border-color: white;
    border-width: 3px;
    border-style: solid;
    max-width: 100%;
    max-height: 100%;
    width: fit-content
  

 
   
   
    

}

.boton{
   height: 30px;
   width: 30px;
   border-radius:15px ;
   background-color: #811b8a;
   color: red;
   border-color: red;
   -moz-transition: all 0.25s ease-out;
            -ms-transition: all 0.25s ease-out;
            -o-transition: all 0.25s ease-out;
            -webkit-transition: all 0.25s ease-out;
   
}

.boton:hover{
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}

.h2Name{
    
    border-radius: 10px;
    border-style: solid;
    border-color: aliceblue;
    max-width:200px ;
    height: auto;
    margin: auto;
    text-align: center;
    background-color: rgb(84, 55, 145);
    color: aliceblue;
    padding:12px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.link{
    text-decoration: none;
}

.columnas{
    display:grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4vh;
    padding: 1vh;
   
}

.pSpecies,.pGender{
    border-radius: 5px;
    border-style: solid;
    border-color: aliceblue;
    height:15px;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    max-width: 200px;
    text-align:center;
    padding: 10px;
    background-color: rgb(84, 55, 145);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: grid;

    
}


[data-tooltip]{ 
    position:relative; 
    border-bottom: 1px dotted #09C;
    cursor:pointer;
    }
    [data-tooltip]::before {
    content : attr(data-tooltip);
    background-color:rgba(0,0,0,.75);
    color:white;
    -webkit-font-smoothing: antialiased;
    font-size: small;
    width:auto;
    padding:10px;
    height:auto;
    border-radius:3px;
    display:none;
    position:absolute;
    bottom:25px;
    left:calc(100% - 100%);
    pointer-events: none;
    z-index:99;
     }
    [data-tooltip]::after{
    content:"";
    height:1px;
    width:1px;
    border:10px solid transparent;
    border-top-color:rgba(0,0,0,.75);
    position:absolute;
    bottom:4px;
    left:50%;
    display: none;
    }
    [data-tooltip]:hover::before,
    [data-tooltip]:hover::after
    { display:block;} 
    

    .btnContainner{
        grid-row: 1/1;
        height:4vh;
        border-color:transparent;
        border-style: solid;
        border-width: 1px;
        justify-self: right;
        padding: 1vh;
    
        
    }

    .nameContainer{
        grid-row:2/2;
        height: 8vh;
        border-color:transparent;
        border-style: solid;
    
    }

    .imgContainer{
        grid-row:3/3;
        height: 30vh;
        border-color:transparent;
        border-style: solid;
        align-self: center;
       justify-self: center;
        
    }

.corazon{
    background-color: transparent;
    border: none;
   
}