


.divSearch{
    border-color: aliceblue;
    border-style: solid;
    border-width: 3px;
    border-radius: 15px;
    height: 80px;
    width: 500px;
    background-color:rgb(84, 55, 145);
    opacity: 0.8;
    margin-left: 0.5em;
    margin-top:0.3em;
    box-shadow: 0 0 20px #4f9;
}

.barra{
    background-color:black;
    height: 40px;
    width: 350px;
    opacity: 0.8;
    color: white;
    margin-left: 1.5em;
    margin-top: 1em;
    
}

.boton{
    background-color:black;
    color:white;
    border-color: antiquewhite;
    border-style: solid;
    border-width: 3px;
    margin-left: 0.5em;
    height: 35px;
    border-radius: 5px;
    
}
.divSearch:hover{
  animation: shake 0.5s;
  animation-iteration-count: infinite;        
}
      
      
      @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        10% { transform: translate(-1px, -2px) rotate(-1deg); }
        20% { transform: translate(-3px, 0px) rotate(1deg); }
        30% { transform: translate(3px, 2px) rotate(0deg); }
        40% { transform: translate(1px, -1px) rotate(1deg); }
        50% { transform: translate(-1px, 2px) rotate(-1deg); }
        60% { transform: translate(-3px, 1px) rotate(0deg); }
        70% { transform: translate(3px, 1px) rotate(-1deg); }
        80% { transform: translate(-1px, -1px) rotate(1deg); }
        90% { transform: translate(1px, 2px) rotate(0deg); }
        100% { transform: translate(1px, -2px) rotate(-1deg); }
      }
    
      @media(max-width: 550px) {
        .divSearch{
          width: 250px;
        }
        .barra{
          width: 100px;
        }
      }

      